.photo-picker {
    -webkit-user-select: none;
    user-select: none;
    min-height: 370px;
}
.photo-picker .flex-row {    
    align-items: center;
    justify-content: center;
}
.photo-picker .image {
    margin: 8px 8px;
    height: 200px;
    width: 200px;
}

.photo-picker .image img {
    height: 200px;
    width: 200px;
    -ms-border-radius: 4px;
    border-radius: 4px;
    -webkit-object-fit: cover;
    object-fit: cover;
    cursor: pointer;
}