a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}
html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}
.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.flapp .navbar-light {
  background: white;
  margin-bottom: 0px !important;
}

@media (min-width: 576px) {
  .flapp .navbar-expand-sm .navbar-nav .nav-link {
      margin-right: 0.3rem;
      margin-left: 0.3rem;
      padding-right: 0.2rem;
      padding-left: 0.2rem;
  }
  
  .nav-link {
    border-bottom: solid 5px white;
  }
  .nav-link.active-link {
    border-bottom: solid 5px #259dd5;
  }
  .colorfull:hover a.text-dark.nav-link {
    border-bottom: 5px solid #259dd5;
  }
}
@media (max-width: 575px) {
  .flapp .navbar-nav .nav-link {
    padding-left: 10px;
    border-left: solid white 6px;
  }
  
  .flapp .navbar-light {
    padding-left: 0.6rem;
  }
  .flapp .navbar-nav .nav-link.active-link {
    border-left: solid #259dd5 6px;
  }
}
.flapp .navbar-light .navbar-toggler {
  border-color: rgba(37, 157, 213,.7);
  z-index: 999999;
}
.flapp .navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30'><path stroke='rgba(37, 157, 213,.8)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;
}
.flapp .navbar-nav .nav-link {
  font-size: 16px;
}
@media (min-width: 768px) {
  .flapp .navbar-nav .nav-link {
    font-size: 18px;
  }
}