@media (max-width: 575px) {
    .main-view .react-simple-keyboard {
        padding: 2px !important;
    }
    .main-view .react-simple-keyboard .hg-row {
        margin-bottom: 2px !important;
    }
    .main-view .react-simple-keyboard .hg-button {
        margin-right: 2px !important;
    }
}
.main-view .react-simple-keyboard {
    font-size: 16px;
    margin-bottom: 5px;
}