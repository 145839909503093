.japanese-text .kanji-picker {
    flex-grow: 1;
    max-width: 100px;
    margin-right: 2px;
}
.japanese-text .small-input {
    flex-grow: 2;
    margin-right: 2px;
}
.japanese-big-input-box {
    display: flex;
}
.japanese-big-input-box .switch-btn {
    margin-right: 5px;
}