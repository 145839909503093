.letter-item {
    cursor: pointer;
    padding: 4px 8px;
    border: 1px solid white;
    border-radius: 4px;
}

.letter-item .result {    
    font-weight: normal;
    text-align: center;
}

.letter-item .value {   
    text-align: center;
}

.letter-item .result.success {
    color: green;
}

.letter-item .result.failure {
    color: red;
}

.letters .to-test.active {
    color: #259dd5;
    border: 1px solid #259dd5;
}

.letters {
    user-select: none;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    font-size: 1.3rem;
    font-weight: bold;
}

@media (max-width: 768px) and (min-width: 576px) {
    .letters {
        padding: 15px;
    }
}

@media (max-width: 575px) {
    .letters {
        padding: 5px;
    }
}

.alphabet-test-buttons {
    margin-top: 5px;
}

.alphabet-header {
    display: flex;
    align-items: center;
}
.alphabet-header h3 {
    margin: 0;
}

.alphabet-header .alphabet-test-buttons {
    margin-top: 0;
    margin-left: 15px;
}

.alphabet-header .alphabet-test-buttons .MuiButton-endIcon {
    margin: 0;
}
.alphabet-settings {
    margin-top: 15px;
}
.alphabet-test {
    margin-bottom: 15px;
}