
.whiteboard-edit {
    -o-justify-content: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
}

.toolbar {
    display: flex;
    justify-content: right;
    flex-direction: column;
    -o-flex-grow: 2;
    -webkit-flex-grow: 2;
    flex-grow: 2;
    align-items: end;
}
.toolbar-inner {
    width: 500px;
    justify-content: center;
    display: flex;
    justify-content: right;
    flex-direction: column;
}

.toolbar .MuiAccordionSummary-content.Mui-expanded {
    margin: 10px 0 !important;
}

.toolbar .form-button {
    margin-top: 5px;
}
.toolbar .MuiAccordion-root.Mui-expanded {
    margin-bottom: 5px;
    margin-top: 5px;
}

@media (max-width: 1024px) {
    .whiteboard-edit {
        -o-justify-content: center;
        -webkit-justify-content: center;
        justify-content: center;
        flex-direction: column;
    }

    .toolbar {
        margin-top: 10px !important;
        -o-justify-content: center;
        -webkit-justify-content: center;
        justify-content: center;
        align-items: center;
    }
}
@media (max-width: 1024px) and (min-width: 576px) {
    .toolbar-inner {
        width: 400px;
    }
}
@media (max-width: 575px) {
    .toolbar-inner {
        width: 350px;
    }
}

.toolbar .Mui-expanded .MuiAccordionDetails-root{
    padding-top: 0;
}

.toolbar .search-result {    
    align-items: center;
    justify-content: center;
}


.whiteboard-edit .canvas-container canvas {
    border: 1px solid silver;
}

.whiteboard-edit .canvas-container:hover canvas {
    box-shadow: 1px 2px 3px silver;
}