a {
  color: #0366d6;
}
.add-definition-buttons {
    margin: 10px 0;
}
code {
  color: #E01A76;
}

.table-striped tr {
    cursor: pointer;
}

.table-striped tr:hover {
    opacity: 0.8
}

.btn-primary {
  color: #fff;
  background-color: #259dd5;
  border-color: #1861ac;
}

.flex-column {
    display: flex;
    flex-direction: column;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}

.flex-row {
    display: flex;
    flex-direction: row;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}

.meaning-box {
    -o-justify-content: center;
    -webkit-justify-content: center;
    justify-content: center;
    -moz-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    -o-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    min-width: 300px;
}

.flashcard-area.meaning-box {
    overflow: visible !important;
}

@media (max-width: 768px) {
    .main-box {
        -o-justify-content: center;
        -webkit-justify-content: center;
        justify-content: center;
    }
}

.flashcard-box.back .flex-row word-box {
    overflow: hidden;
}

.flashcard-box.back .flashcard-body .flashcard-area {
    overflow: visible;
}

.meaning-box .word-box {
    flex-wrap: nowrap;
}

.flex-basis-50 {
    -ms-flex-basis: 50%;
    -o-flex-basis: 50%;
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
    padding: 0px 30px;
}

.flashcard .image {
    display: flex;
    align-items: center;
    flex-direction: column;
    -o-justify-content: center;
    -webkit-justify-content: center;
    justify-content: center;
    margin: 0px 20px;
    height: 200px;
    width: 200px;
}

.meaning {
    font-size: xx-large;
}
.definition {
    font-size: xx-large;
}
.custom-definition {
    font-size: larger;
}
.speaker {
    display: flex;
    align-items: end;
    padding: 10px 10px 6px 10px;
    cursor: pointer;
}
.speaker:hover {
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.inline-list {
    padding: 0px 20px;
}

.inline-list span {
    padding: 0px 5px;
}

.image img {
    height: 200px;
    width: 280px;
    -ms-border-radius: 4px;
    border-radius: 4px;
    -webkit-object-fit: cover;
    object-fit: cover;
    cursor: pointer;
}

.image img:hover {
    -ms-opacity: 0.8;
    opacity: 0.8;
    -ms-transform: scale(1.05);
    -webkit-transform: scale(1.05);
    transform: scale(1.05);    
    box-shadow: 1px 2px 3px silver;
}

.image svg {
    -ms-opacity: 0.8;
    opacity: 0.8;
    cursor: pointer;
}

.image svg:hover {
    -ms-opacity: 1;
    opacity: 1;
    -ms-transform: scale(1.05);
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}

.flashcard.reverse input.custom-definition-input {
    font-size: 16px;
    width: 50%;
    display: inline;
    height: 24px;
    padding: 0px 2px;
    margin: 0 3px;
}

.bottom-box {
    margin-top: 10px;
}


.history-box-item {
    padding: 0px 1px;
}
.center {
    text-align: center;
}
.nowrap {
    white-space: nowrap;
}
.table td {
    height: 58px;
    vertical-align: middle !important;
}
.whiteboard-front {
    height: 200px;
    margin-top: -200px;
    -ms-opacity: 0;
    opacity: 0;
    cursor: pointer;
}

.whiteboard-box:hover {
    -ms-opacity: 0.8;
    opacity: 0.8;
}


.top-bar {
    margin: 0 0 10px 0;
    height: 60px;
    display: flex;
    flex-direction: row;
    background: white;
}

.color-picker-wb {
    margin: 10px 10px;
    border: 1px solid black;
}

.slider {
    width: 200px;
}

.flashcard-box {
    display: flex;
    flex-direction: column;
    border: 1px solid silver;
    padding: 10px;
    -ms-transition: all .32s ease-in-out;
    -o-transition: all .32s ease-in-out;
    -webkit-transition: all .32s ease-in-out;
    transition: all .32s ease-in-out;
    min-height: 370px;
    -o-justify-content: flex-start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-user-select: none;
    user-select: none;
    -moz-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    -o-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    overflow: hidden;
    border-left: 15px solid #259dd5;
    background: white;
}

.MuiDialog-container .flashcard-box {    
    min-height: auto;
    overflow: auto;
}

.flashcard-menu {
    position: relative;
}

.flashcard-box:hover {
    box-shadow: 3px 4px 5px silver;
    -ms-opacity: 1;
    opacity: 1;
}

.flashcard-box .flashcard-toolbar {
    display: flex;
    flex-direction: row;
    -o-justify-content: center;
    -webkit-justify-content: center;
    justify-content: center;
    height: 38px;
}

.flashcard-box .flashcard-body {
    padding: 10px;
    display: flex;
    flex-direction: row;
    -o-justify-content: flex-start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}

@media (max-width: 768px) {
    .flashcard-box .flashcard-body {
        -o-justify-content: center;
        -webkit-justify-content: center;
        justify-content: center;
    }
    .flashcard-box.back .flashcard-body.edit-mode .flashcard-area {
        justify-content: start !important;
        height: auto !important;
        min-height: 150px !important;
    }
    .flashcard-box.back .flashcard-body .flashcard-area {
        height: 150px !important;
    }
    .flashcard-box.back .flashcard-body .flashcard-area.notes {
        width: 600px !important;
        height: 130px !important;
    }
    .flashcard-box .flashcard-toolbar {
        justify-content: start !important;
    }
    .flashcard-area.image img {
        height: 150px !important;
        width: 210px !important;
    }
}
.flashcard-box .flashcard-body .flashcard-area {
    display: flex;
    flex-direction: row;
    min-width: 300px;
    height: 220px;
    padding: 10px;
    overflow: hidden;
}

.flashcard-box .flashcard-body .flashcard-area.image {
    display: flex;
    flex-direction: row;
    -o-justify-content: center;
    -webkit-justify-content: center;
    justify-content: center;
    overflow: hidden;
}

.flashcard-box .flashcard-body .flashcard-area.whiteboard {
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.flashcard-box .flashcard-body .flashcard-area.word {
    display: flex;
    -o-justify-content: center;
    -webkit-justify-content: center;
    justify-content: center;
    flex-direction: column;
}

.flashcard-box .flashcard-body .flashcard-area.notes {
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.flashcard-box .flashcard-body .flashcard-area.info {
    display: flex;
    flex-direction: column;
    width: 600px;
}

.whiteboard-2:hover {
    box-shadow: 3px 4px 5px silver;
}

.toolbar-btn {
    margin: 0px 20px !important;
}

@media (max-width: 768px) {
    .toolbar-btn {
        margin: 0px 5px !important;
    }
}

.flipFront {
     height: 300px;
    -ms-perspective: 1000px;
    -webkit-perspective: 1000px;
    perspective: 1000px;
}

.flipFrontInner {
    width: 100%;
    height: 100%;
    -ms-transition: transform 0.6s;
    -o-transition: transform 0.6s;
    -webkit-transition: transform 0.6s;
    transition: transform 0.6s;
    -ms-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.flipFront.rotated .flipFrontInner {
    -ms-transform: rotateX(180deg);
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
}

.flip-box {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -ms-transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg)
}

.flashcard-box.front {
    border-left: 1px solid silver;
}

.flipFrontInner .flip-box.back {
    -ms-transform: rotateX(180deg);
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
}

.sketch-picker .flexbox-fix:nth-child(3) {
    display: none !important;
}

.main-view {
    padding-top: 80px;
    height: 100%;
    position: fixed;
    display: flex;
    flex-flow: row nowrap;
    top: 0;
    right: 0;
    overflow: hidden;
    left: 0;
}

.main-view > .container {    
    padding: 0.5rem 1.5rem 1.5rem 1.5rem;
    background: white;
    box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 5%);
    margin-bottom: 80px;
    overflow-y: auto;
    overflow-x: hidden;
}

.main-view > .container.definitions {    
    overflow-x: auto;
}

@media (max-width: 575px) {
    .main-view {
        padding-top: 70px;
    }
    .main-view > .container {    
        padding: 0.5rem 0.7rem;
        margin-bottom: 15px;
    }
}

body {
    background: #8080800f;
}

.flapp {
    margin-bottom: 0px;
    background: white;
}

tr.plan-row.selected {
    border: 1.5px solid #259dd5;
}